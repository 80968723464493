<template>
  <div class="bac">
    <el-form ref="dialogForm" :model="subForm.project"  label-width="100px">

      <el-form-item label="上传封面" prop="title">
        <img :src="subForm.project.pic" alt="" v-show="subForm.project.pic !== ''" style="width: 156px;">
        <el-upload ref="upload"
                   action="#"
                   :auto-upload="false"
                   accept="image/png,image/gif,image/jpg,image/jpeg"
                   list-type="picture-card"
                   :show-file-list="false"
                   :on-change="imgChange"
                   v-if="subForm.project.pic === ''">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="项目名称" prop="title">
        <el-input v-model="subForm.project.projectName" />
      </el-form-item>

      <el-form-item label="公司名称" prop="title">
        <el-input v-model="subForm.project.companyName" />
      </el-form-item>

      <el-form-item label="联系人" prop="title">
        <el-input v-model="subForm.project.linkman" />
      </el-form-item>

      <el-form-item label="联系电话" prop="title">
        <el-input v-model="subForm.project.phone" />
      </el-form-item>

      <el-form-item label="项目类别" prop="title">
        <el-cascader :options="options" :show-all-levels="false" v-model="cascaderTemp" placeholder="请选择"/>
      </el-form-item>

      <el-form-item label="项目周期(天)" prop="title">
        <el-input-number v-model="subForm.project.period" :min="1"/>
      </el-form-item>

      <el-form-item label="项目背景" prop="title">
        <el-input
            v-model="subForm.project.background"
            :autosize="{ minRows: 2, maxRows: 5 }"
            type="textarea"
        />
      </el-form-item>

      <el-form-item label="风格要求" prop="title">
        <el-input v-model="subForm.project.style" />
      </el-form-item>

      <el-form-item label="目标用户" prop="title">
        <el-input v-model="subForm.project.targetPeople" />
      </el-form-item>

      <el-form-item label="其他信息" prop="title">
        <el-input
            v-model="subForm.project.otherInformation"
            :autosize="{ minRows: 2, maxRows: 5 }"
            type="textarea"
        />
      </el-form-item>

      <el-form-item label="设计交付物" prop="title">
        <el-checkbox-group v-model="checkBoxTemp1">
          <el-checkbox label="初稿方案" />
          <el-checkbox label="终稿方案" />
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="中标交付物" prop="title">
        <el-checkbox-group v-model="checkBoxTemp2">
          <el-checkbox label="提案方案" />
          <el-checkbox label="设计效果稿" />
          <el-checkbox label="设计源文件" />
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="保存" prop="title">
        <el-button type="primary" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
let formData = new FormData();
export default {
  name: "RequestDetail",
  data(){
    return{
      options:[
        {
          value: '产品设计',
          label: '产品设计',
          children: [
            {
              value: '医疗设备',
              label: '医疗设备',
            },
            {
              value: '仪器设备',
              label: '仪器设备',
            },
            {
              value: '消费电子',
              label: '消费电子',
            },
            {
              value: '交通出行',
              label: '交通出行',
            },
            {
              value: '工业设备',
              label: '工业设备',
            },
            {
              value: '机器人',
              label: '机器人',
            },]
        },
        {
          value: '研发工程',
          label: '研发工程',
          children: [
            {
              value: '结构设计',
              label: '结构设计',
            },
            {
              value: '自动化设计',
              label: '自动化设计',
            },]
        },
        {
          value: '传播设计',
          label: '传播设计',
          children: [
            {
              value: '品牌vi',
              label: '品牌vi',
            },
            {
              value: '电商视觉',
              label: '电商视觉',
            },
            {
              value: '网站/APP',
              label: '网站/APP',
            },
            {
              value: '产品包装',
              label: '产品包装',
            },
            {
              value: 'Logo设计',
              label: 'Logo设计',
            },
            {
              value: '画册设计',
              label: '画册设计',
            },
            {
              value: 'UI设计',
              label: 'UI设计',
            },]
        },
        {
          value: '空间设计',
          label: '空间设计',
          children: [
            {
              value: '商业空间',
              label: '商业空间',
            },
            {
              value: '厂房改造',
              label: '厂房改造',
            },
            {
              value: '会展陈列',
              label: '会展陈列',
            },
            {
              value: '空间导视',
              label: '空间导视',
            },]
        }
      ],

      checkBoxTemp1:[],
      checkBoxTemp2:[],
      cascaderTemp:[],

      subForm:{
        sid:'',
        project:{
          pic:'',
          companyName:'',
          linkman:'',
          phone:'',
          projectName:'',
          typeFirst:'',
          typeSecond:'',
          period:'',
          background:'',
          style:'',
          targetPeople:'',
          otherInformation:'',
          deliverableType:'',
        }
      },

    }
  },
  mounted() {
    this.subForm.sid = this.$route.query.companyId;
  },
  methods:{

    submit(){
      this.subForm.project.typeFirst = this.cascaderTemp[0];
      this.subForm.project.typeSecond = this.cascaderTemp[1];

      for(let i = 0;i<this.checkBoxTemp1.length; i++){
        this.subForm.project.deliverableType+= this.checkBoxTemp1[i];
        this.subForm.project.deliverableType+= ',';
      }

      for(let i = 0;i<this.checkBoxTemp2.length; i++){
        this.subForm.project.deliverableType+= this.checkBoxTemp2[i];
        this.subForm.project.deliverableType+= ',';
      }

      this.$http({
        method: 'post',
        url: '/admin/addProjectForSupplier',
        data: this.subForm,
      }).then(res => {
        this.$notify({
          title: '操作成功',
          message: '',
          type: 'success'
        });
        this.$router.push({path:'/AllProject',query: {companyId: this.subForm.sid}})
      });
    },

    //封面
    imgChange (files, fileList) {
      if (!(fileList[0].raw.type === 'image/png' || fileList[0].raw.type === 'image/gif' || fileList[0].raw.type === 'image/jpg' || fileList[0].raw.type === 'image/jpeg')) {
        this.$notify.warning({
          title: '警告',
          message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
        })
        return;
      }
      let size = fileList[0].raw.size / 1024 / 1024 / 2
      if (size > 2) {
        this.$notify.warning({
          title: '警告',
          message: '图片大小必须小于2M'
        })
        return;
      }

      formData.append('file',fileList[0].raw)

      this.$http({
        method: 'post',
        url: '/admin/uploadPicForProjectForSupplier',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.subForm.project.pic = res.data;
      })
    },

  },
}
</script>

<style scoped>

</style>
